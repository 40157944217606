/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import step2 from '../../../images/custom/screens/Customcounsellormatchcard.png';
import french2 from '../../../images/custom/screens/Customcounsellormatchcardfrench.png';

const featureTwo = {
  stepId: 'new.stepTwo',
  step: 'STEP TWO',
  id: 'eap.selectProvider',
  title: 'Select a provider from your customized results',
  descriptionId: 'eap.afterMatching',
  description:
    "After the matching survey, we'll direct you to a provider selection page where matches will be ranked based on effectiveness and your individual needs.",
  image: step2,
  frImage: french2,
};

const V2Section5 = props => {
  const {
    stepId,
    step,
    id,
    title,
    description,
    descriptionId,
    image,
    frImage,
  } = featureTwo;
  return (
    <div className="v2-section5">
      <div className="v2-s5-image">
        <div
          alt=""
          className="bg-url-image"
          style={{
            backgroundImage: `url(${
              localStorage.getItem('selectedLanguage') !== 'fr'
                ? image
                : frImage
            })`,
          }}
        />
        <img
          className="url-image"
          src={
            localStorage.getItem('selectedLanguage') !== 'fr' ? image : frImage
          }
          alt={title}
        />
      </div>
      <div className="v2-s5-text">
        <div className="text">
          <div className="step-number font-open-sans-blue-16 font-open-sans-blue-16--bold">
            {props.intl.formatMessage({ id: stepId, defaultMessage: step })}
          </div>
          <div className="step-title font-barlow-34">
            <span>
              {props.intl.formatMessage({ id, defaultMessage: title })}
            </span>
          </div>
          <div className="step-description font-open-sans-20">
            {props.intl.formatMessage({
              id: descriptionId,
              defaultMessage: description,
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

V2Section5.propTypes = {
  intl: PropTypes.object,
};

V2Section5.defaultProps = {
  intl: {},
};

export default injectIntl(V2Section5);
