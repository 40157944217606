/* eslint-disable no-template-curly-in-string */
import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { COUNTRY } from '../../../utils/environment';

export const dropdownDataOne = [
  {
    id: 1,
    question: (
      <FormattedHTMLMessage
        tagName="p"
        id="new.notEnoughTime"
        defaultMessage="I don't have enough time, my life is already so busy."
      />
    ),
    answer: (
      <div>
        <FormattedHTMLMessage
          tagName="p"
          id="new.googleCalendar"
          defaultMessage="Your Google Calendar is fuller than your laundry basket, and you can’t even fathom fitting in the time it it takes to get to and from a therapist’s office."
        />
        <FormattedHTMLMessage
          tagName="p"
          id="new.massiveCommitment"
          defaultMessage="It seems like such a massive commitment to spend an hour talking about yourself when you already feel like there aren’t enough minutes in a day."
        />
        <FormattedHTMLMessage
          tagName="p"
          id="new.timeIsValuable"
          defaultMessage="We understand your time is valuable, and that’s why we wanted to give you access to sessions via video so that you can spend your precious time getting care, rather than sitting in your car."
        />
      </div>
    ),
  },
  {
    id: 2,
    question: (
      <FormattedHTMLMessage
        tagName="p"
        id="new.cantAffordIt"
        defaultMessage="I can't afford therapy - it's too expensive"
      />
    ),
    answer: (
      <div>
        <FormattedHTMLMessage
          tagName="p"
          id="new.budgetAlreadyTight"
          values={{ price: COUNTRY === 'US' ? '150' : '120' }}
          defaultMessage="Your budget is already tight, and the thought of spending over ${price} per hour to talk about your feelings doesn’t sit well. You’re unsure if you’re even ready for therapy, so the thought of dropping that much cash makes you feel even more anxious that you already are."
        />
        <FormattedHTMLMessage
          tagName="p"
          id="new.weHearYou"
          defaultMessage="We hear you. The average cost of traditional therapy is $60-100 per 30 minutes, and if you don’t have insurance, effective treatment can really add up."
        />
        <FormattedHTMLMessage
          tagName="p"
          id="new.just3750"
          values={{ price: COUNTRY === 'US' ? '45' : '37.50', firstSession: COUNTRY === 'US' ? '' : 'Your first session is also always free until you find the right fit.' }}
          defaultMessage="On Inkblot, you can see a Care Provider for just ${price} per 30 minute session. {firstSession}"
        />
      </div>
    ),
  },
  {
    id: 3,
    question: (
      <FormattedHTMLMessage
        tagName="p"
        id="new.hateTalkingAbout"
        defaultMessage="I hate talking about myself, and thinking about my problems just makes me feel more anxious."
      />
    ),
    answer: (
      <div>
        <FormattedHTMLMessage
          tagName="p"
          id="new.introductionGames"
          defaultMessage="You’re the kind of person who dreads go-around-in-the-circle introduction games. Sharing your thoughts tends to make you feel worse and you’d rather just keep them to yourself."
        />
        <FormattedHTMLMessage
          tagName="p"
          id="new.talkingAboutYourself"
          defaultMessage="Talking about yourself in therapy won’t feel like an awkward networking event. We promise."
        />
        <FormattedHTMLMessage
          tagName="p"
          id="new.professionallyTrained"
          defaultMessage="Our Care Providers are professionally trained to help you discover and unpack your inner voice in a safe and judgement free space."
        />
      </div>
    ),
  },
  {
    id: 4,
    question: (
      <FormattedHTMLMessage
        tagName="p"
        id="new.uniqueSituation"
        defaultMessage="My situation is unique, nobody could possibly understand it."
      />
    ),
    answer: (
      <div>
        <FormattedHTMLMessage
          tagName="p"
          id="new.reallyDifficult"
          defaultMessage="It’s really difficult for others to understand what you’ve been through. Life has been complicated and traumatic. You don’t feel that anyone is equipped to help."
        />
        <FormattedHTMLMessage
          tagName="p"
          id="new.youreSick"
          defaultMessage="You’re sick of hearing people say they ‘understand what you’re going through and you’re only willing to open up to someone who won’t pander to you."
        />
        <FormattedHTMLMessage
          tagName="p"
          id="new.onInkblot"
          defaultMessage="On Inkblot, you will be able to work with Care Providers who will use their professional training to help unpack your unique circumstances."
        />
      </div>
    ),
  },
  {
    id: 5,
    question: (
      <FormattedHTMLMessage
        tagName="p"
        id="new.nobodyIKnow"
        defaultMessage="Nobody I know has been to therapy and they're all fine. Plus if I go, they will judge me."
      />
    ),
    answer: (
      <div>
        <FormattedHTMLMessage
          tagName="p"
          id="new.therapyIsFor"
          defaultMessage="You’ve been told ‘therapy is for people who can’t handle their own problems’ more times than you can count. If you decided to talk to someone now, you’d be seen as weak and needy."
        />
        <FormattedHTMLMessage
          tagName="p"
          id="new.fullyConfidential"
          defaultMessage="Thankfully, Inkblot is fully confidential, and all your private information is kept safe, so your sessions are only between you and your Care Provider."
        />
        <FormattedHTMLMessage
          tagName="p"
          id="new.talkTherapy"
          defaultMessage="When you start feeling better, we bet you’ll even want to tell your judgey aunt Karen all about the benefits of talk therapy."
        />
      </div>
    ),
  },
  {
    id: 6,
    question: (
      <FormattedHTMLMessage
        tagName="p"
        id="new.chemicalImbalance"
        defaultMessage="I have a chemical imbalance, so only medication works for me."
      />
    ),
    answer: (
      <div>
        <FormattedHTMLMessage
          tagName="p"
          id="new.sinceStartingTreatment"
          defaultMessage="You’ve been to your doctor and been diagnosed with a clinical mental health condition which is treatable by medication. Since starting treatment, you’re feeling so much better and therefore see no need for therapy."
        />
        <FormattedHTMLMessage
          tagName="p"
          id="new.noDoubt"
          defaultMessage="There’s no doubt that the medication is helpful, and likely a big reason why you’re back on track."
        />
        <FormattedHTMLMessage
          tagName="p"
          id="new.medicationCombined"
          defaultMessage="Medication combined with talk therapy is 40% more effective than either alone. Counselling helps you learn how to cope better in the long term and feel better on a day to day basis."
        />
      </div>
    ),
  },
];

export const dropdownDataTwo = [
  {
    id: 1,
    question: (
      <FormattedHTMLMessage
        tagName="p"
        id="new.whatIsInkblot"
        defaultMessage="What is Inkblot and how does it work?"
      />
    ),
    answer: (
      <div>
        <FormattedHTMLMessage
          tagName="p"
          id="new.canadasFastestGrowing"
          defaultMessage="Inkblot is Canada’s fastest-growing digital mental health platform on a mission to improve your mental health at home, at work and beyond. We offer accessible, affordable and effective digital mental health solutions for individuals and organizations using secure virtual counselling, personalized therapist matching services and digital-first employee wellness programs."
        />
      </div>
    ),
  },
  {
    id: 2,
    question: (
      <FormattedHTMLMessage
        tagName="p"
        id="new.inkblotDifferentFrom"
        defaultMessage="How is Inkblot different from other digital therapy platforms?"
      />
    ),
    answer: (
      <div>
        <FormattedHTMLMessage
          tagName="p"
          id="new.removesBarriers"
          defaultMessage="Inkblot removes barriers to accessing the right support and matches you with qualified mental health practitioners based on your unique needs and preferences. All activity, including confidential video/voice sessions, booking and payment, happens on our platform for convenience and maximum security and confidentiality."
        />
        {/* <FormattedHTMLMessage
          tagName="p"
          id="new.curious"
          defaultMessage="Curious to know more?"
        />
        <button style={{ color: 'rgba(18, 33, 103, 0.9)',
        border: 'solid 2px rgba(18, 33, 103, 0.9)', marginTop: '20px' }}>
          See how my data is used
        </button> */}
      </div>
    ),
  },
  {
    id: 3,
    question: (
      <FormattedHTMLMessage
        tagName="p"
        id="new.howDoYouMatch"
        defaultMessage="How do you match me with the right mental health practitioner?"
      />
    ),
    answer: (
      <div>
        <FormattedHTMLMessage
          tagName="p"
          id="new.providesUniqueMatching"
          defaultMessage="Inkblot provides a unique matching survey that helps us effectively evaluate your specific needs to match you with the right practitioner. By answering a few quick questions, we’ll be able to determine your best fit based on factors such as symptoms, stressors, language, and therapists who share your culture and values. You can also review things like clinical specialties, therapeutic approaches and more."
        />
      </div>
    ),
  },
  {
    id: 4,
    question: (
      <FormattedHTMLMessage
        tagName="p"
        id="new.howMuch"
        defaultMessage="How much does it cost?"
      />
    ),
    answer: (
      <div>
        <FormattedHTMLMessage
          tagName="p"
          id="new.providesComplimentary"
          values={{ price: COUNTRY === 'US' ? '110' : '90' }}
          defaultMessage="Inkblot provides 15-minute complimentary consultations with each new practitioner a client selects. After the initial complimentary consultation, clients will pay the rate outlined in their practitioner’s profile, should they choose to continue with them. Individual therapy sessions cost ${price} per hour.<br /><br />Learn more about Inkblot Therapy session costs."
        />
      </div>
    ),
  },
  {
    id: 5,
    question: (
      <FormattedHTMLMessage
        tagName="p"
        id="new.whoAreThe"
        defaultMessage="Who are the therapists and how are they verified?"
      />
    ),
    answer: (
      <div>
        <FormattedHTMLMessage
          tagName="p"
          id="new.mentalHealthPractitioners"
          defaultMessage="Inkblot mental health practitioners undergo a stringent vetting process before becoming part of our network. Generally, our practitioners include licensed Master-level counsellors, social workers, psychotherapists and psychologists who are in good standing with their provincial or state board. They are experienced in various clinical settings and must obtain specific designations to practice."
        />
      </div>
    ),
  },
  {
    id: 6,
    question: (
      <FormattedHTMLMessage
        tagName="p"
        id="new.informationConfidential"
        defaultMessage="Is my information & sessions with therapists confidential?"
      />
    ),
    answer: (
      <div>
        <FormattedHTMLMessage
          tagName="p"
          id="new.notRecorded"
          defaultMessage="Video and voice sessions on Inkblot are not recorded or stored on any server. A military-grade encrypted link for your session is made directly between you and your care provider, which bypasses our servers."
        />
      </div>
    ),
  },
  {
    id: 7,
    question: (
      <FormattedHTMLMessage
        tagName="p"
        id="new.informationProtected"
        defaultMessage="How are my personal information and privacy protected?"
      />
    ),
    answer: (
      <div>
        <FormattedHTMLMessage
          tagName="p"
          id="new.storedOn"
          defaultMessage="Personal information, such as your name, address, and any of your practitioners’ notes and documents, are stored on a dedicated server with its own protected network located in either Canada or the USA (depending on your location). This data is encrypted at the highest possible level, and the server is audited regularly to ensure it meets the highest standards of best security practices (SOC 2 certified).<br /><br />Please review our <a style='color:blue;text-decoration:underline;' href='https://inkblottherapy.com/privacy' target='_blank'>Privacy Policy</a> for more information."
        />
      </div>
    ),
  },
  {
    id: 8,
    question: (
      <FormattedHTMLMessage
        tagName="p"
        id="new.howCanMy"
        defaultMessage="How can my business use Inkblot Therapy?"
      />
    ),
    answer: (
      <div>
        <FormattedHTMLMessage
          tagName="p"
          id="new.inkblotOffers"
          defaultMessage="Inkblot Therapy offers Employee Assistance Programs (EAPs) to keep employees mentally healthy and motivated with effective and affordable mental health solutions, including secure virtual counselling, personalized therapist matching services and digital-first employee wellness programs.<br /><br />Learn more about <a style='color:blue;text-decoration:underline;' href='https://business.inkblottherapy.com/' target='_blank'>Inkblot Therapy for business</a>."
        />
      </div>
    ),
  },
  {
    id: 9,
    question: (
      <FormattedHTMLMessage
        tagName="p"
        id="new.amTherapist"
        defaultMessage="I am a therapist. How do I work with Inkblot?"
      />
    ),
    answer: (
      <div>
        <FormattedHTMLMessage
          tagName="p"
          id="new.helpsQualified"
          defaultMessage="Inkblot Practice helps qualified mental health practitioners grow their practice, eliminate administrative tasks and fees, and access a secure video platform. Our simple practice management tools are available to all healthcare providers in Canada and the United States at the lowest possible cost.<br /><br />Learn more about <a style='color:blue;text-decoration:underline;' href='https://inkblotpractice.com/' target='_blank'>Inkblot Practice</a> and register today."
        />
      </div>
    ),
  },
];
