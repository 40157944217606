import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

const Section8 = () => (
  <div className="section-8">
    <h1>
      <FormattedHTMLMessage
        id="new.getTheHelp"
        defaultMessage="<b>Get the help you need when you need it.</b><br /><br />Secure virtual counselling, personalized therapist matching services and digital-first employee wellness programs."
      />
    </h1>
  </div>
);

Section8.propTypes = {};

export default Section8;
