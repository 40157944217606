import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import Section1 from '../pagePartials/new/Section1';
import Section2 from '../pagePartials/new/Section2';
import Section6 from '../pagePartials/new/Section6';
import Section6a from '../components/ReferralLanding/Sections/Section4';
import Section6b from '../components/ReferralLanding/Sections/Section5';
import Section6c from '../components/ReferralLanding/Sections/Section6';
import Section6d from '../components/ReferralLanding/Sections/Section7';
import Section7 from '../pagePartials/new/Section7';
import Section8 from '../pagePartials/new/Section8';
import Section9 from '../components/ReferralLanding/Sections/Section10';
import Section10 from '../pagePartials/new/Section10';

const Index = props => (
  <Layout location={props.location}>
    <div className="new" role="main">
      <Section1 />
      <Section2 />
      <Section6 />
      <Section6a />
      <Section6b />
      <Section6c />
      <Section6d />
      <Section9 />
      <Section7 />
      <Section8 />
      <Section10 />
    </div>
  </Layout>
);

Index.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Index;
