/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import step4 from '../../../images/referral/step-4.png';
import french4 from '../../../images/custom/screens/group2.png';
import ReferralSteps from './ReferralSteps';

const featureFour = {
  stepId: 'new.stepFour',
  step: 'STEP FOUR',
  id: 'eap.scheduleFirst',
  title: 'Schedule your first confidential video session',
  descriptionId: 'eap.bookSecure',
  description:
    'You can book a therapy session via secure video from the comfort of your own space with your selected provider based on your respective schedules.',
  image: step4,
  frImage: french4,
};

const Section7 = props => {
  const {
    stepId,
    step,
    id,
    title,
    description,
    descriptionId,
    image,
    frImage,
  } = featureFour;
  return (
    <div className="v2-section7">
      <div className="v2-s7-image">
        <div
          alt=""
          className="bg-url-image"
          style={{
            backgroundImage: `url(${
              localStorage.getItem('selectedLanguage') !== 'fr'
                ? image
                : frImage
            })`,
          }}
        />
        <img
          className="url-image"
          src={
            localStorage.getItem('selectedLanguage') !== 'fr' ? image : frImage
          }
          alt={title}
        />
      </div>
      <div className="v2-s7-text">
        <div className="text">
          <div className="step-number font-open-sans-blue-16 font-open-sans-blue-16--bold">
            {props.intl.formatMessage({ id: stepId, defaultMessage: step })}
          </div>
          <div className="step-title font-barlow-34">
            <span>
              {props.intl.formatMessage({ id, defaultMessage: title })}
            </span>
          </div>
          <div className="step-description font-open-sans-20">
            {props.intl.formatMessage({
              id: descriptionId,
              defaultMessage: description,
            })}
          </div>
        </div>
        <div className="steps">
          <ReferralSteps currentStep={4} />
        </div>
      </div>
    </div>
  );
};

Section7.propTypes = {
  intl: PropTypes.object,
};

Section7.defaultProps = {
  intl: {},
};

export default injectIntl(Section7);
