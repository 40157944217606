/* eslint-disable arrow-body-style */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import logoTable from '../../../images/referral/logo-table.svg';
import { COUNTRY } from '../../../utils/environment';

const Section10 = () => {
  return (
    <div className="v2-section10">
      <div className="v2-s10-title">
        <FormattedMessage
          id="new.accessibleTailored"
          defaultMessage="Inkblot therapy is accessible, affordable and designed for you."
        />
      </div>
      <div className="v2-s10-table">
        <div className="v210t-row row-1">
          <div className="v210t-col col-1">
            <FormattedMessage
              id="new.howWeCompare"
              defaultMessage="How we compare"
            />
          </div>
          <div className="v210t-col col-2">
            <FormattedMessage
              id="new.traditionalCounselling"
              defaultMessage="Traditional Counselling"
            />
          </div>
          <div className="v210t-col col-3">
            <img src={logoTable} alt="logo" />
          </div>
        </div>
        <div className="v210t-row row-2">
          <div className="v210t-col col-1">
            <FormattedMessage
              id="new.costPer60"
              defaultMessage="Cost per hour"
            />
          </div>
          <div className="v210t-col col-2">{COUNTRY === 'US' ? '$150 - $200' : '$110 - $150'}</div>
          <div className="v210t-col col-3">
            {COUNTRY === 'US' ? '$110' : '$90'}
          </div>
        </div>
        <div className="v210t-row row-4">
          <div className="v210t-col col-1">
            <FormattedMessage
              id="new.accessability"
              defaultMessage="Accessibility"
            />
          </div>
          <div className="v210t-col col-2">
            <FormattedMessage
              id="new.inAnOffice"
              defaultMessage="In an office"
            />
          </div>
          <div className="v210t-col col-3">
            <FormattedMessage
              id="new.anywhereInCanada"
              defaultMessage="Anywhere in Canada"
            />
          </div>
        </div>
        <div className="v210t-row row-6">
          <div className="v210t-col col-1">
            <FormattedMessage
              id="new.sessionLocation"
              defaultMessage="Session Location"
            />
          </div>
          <div className="v210t-col col-2">
            <FormattedMessage
              id="new.locationDependant"
              defaultMessage="Location dependent"
            />
          </div>
          <div className="v210t-col col-3">
            <FormattedMessage id="new.anywhere" defaultMessage="Anywhere" />
          </div>
        </div>
        <div className="v210t-row row-7">
          <div className="v210t-col col-1">
            <FormattedMessage
              id="new.sessionTime"
              defaultMessage="Session Time"
            />
          </div>
          <div className="v210t-col col-2">
            <FormattedMessage
              id="new.basedOnProviders"
              defaultMessage="Based on care provider’s chosen schedule"
            />
          </div>
          <div className="fake-shadow" />
          <div className="v210t-col col-3">
            <FormattedMessage
              id="new.chooseCareProvider"
              defaultMessage="Choose a Care Provider that fits your schedule"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section10;
