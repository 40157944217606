import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import testimonialBg1 from '../../images/testimonial-bg/customer-testimonial-bg-1.png';
import testimonialBg1Tablet from '../../images/testimonial-bg/customer-testimonial-bg-1-tablet.png';
import testimonialBg1Mobile from '../../images/testimonial-bg/customer-testimonial-bg-1-mobile.png';
import testimonialBg2 from '../../images/testimonial-bg/customer-testimonial-bg-2.png';
import testimonialBg2Tablet from '../../images/testimonial-bg/customer-testimonial-bg-2-tablet.png';
import testimonialBg2Mobile from '../../images/testimonial-bg/customer-testimonial-bg-2-mobile.png';
import testimonialBg3 from '../../images/testimonial-bg/customer-testimonial-bg-3.png';
import testimonialBg3Tablet from '../../images/testimonial-bg/customer-testimonial-bg-3-tablet.png';
import testimonialBg3Mobile from '../../images/testimonial-bg/customer-testimonial-bg-3-mobile.png';
import testimonialBg4 from '../../images/testimonial-bg/customer-testimonial-bg-4.png';
import testimonialBg4Tablet from '../../images/testimonial-bg/customer-testimonial-bg-4-tablet.png';
import testimonialBg4Mobile from '../../images/testimonial-bg/customer-testimonial-bg-4-mobile.png';
import testimonialBg5 from '../../images/testimonial-bg/customer-testimonial-bg-5.png';
import testimonialBg5Tablet from '../../images/testimonial-bg/customer-testimonial-bg-5-tablet.png';
import testimonialBg5Mobile from '../../images/testimonial-bg/customer-testimonial-bg-5-mobile.png';
import testimonialBg6 from '../../images/testimonial-bg/customer-testimonial-bg-6.png';
import testimonialBg6Tablet from '../../images/testimonial-bg/customer-testimonial-bg-6-tablet.png';
import testimonialBg6Mobile from '../../images/testimonial-bg/customer-testimonial-bg-6-mobile.png';

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} next-arrow`}
      style={{
        ...style,
      }}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex={0}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} prev-arrow`}
      style={{
        ...style,
      }}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex={0}
    />
  );
}

export function Section7() {
  const settings = {
    dots: false,
    swipe: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  return (
    <div className="section-7">
      <div className="header">
        <FormattedHTMLMessage
          tagName="h1"
          id="new.careProvidersWho"
          defaultMessage="Care providers who understand your individual needs"
        />
        <FormattedHTMLMessage
          tagName="p"
          id="new.meetAFew"
          defaultMessage="Our professional network includes therapists in over 20 different languages, specializing across various techniques and backgrounds.<br /><br /> Here's what our users have to say about working with Inkblot practitioners."
        />
      </div>
      <div className="testimonial-carousel">
        <Slider {...settings}>
          <div className="testimonial-container">
            <img
              src={windowWidth > 1155 ? testimonialBg1 : testimonialBg1Tablet}
              alt="League background"
              className="league-background-desktop"
            />
            <img
              src={testimonialBg1Mobile}
              alt="League background"
              className="league-background-mobile"
            />
            <div>
              <p>
                <FormattedMessage id="new.quoteOne" defaultMessage="" />
              </p>
              <h4>- Kasadee</h4>
            </div>
          </div>
          <div className="testimonial-container">
            <img
              src={windowWidth > 1155 ? testimonialBg2 : testimonialBg2Tablet}
              alt="dmz background"
              className="league-background-desktop"
            />
            <img
              src={testimonialBg2Mobile}
              alt="dmz background"
              className="league-background-mobile"
            />
            <div>
              <p>
                <FormattedMessage id="new.quoteTwo" defaultMessage="" />
              </p>
              <h4>- Ana</h4>
            </div>
          </div>
          <div className="testimonial-container">
            <img
              src={windowWidth > 1155 ? testimonialBg3 : testimonialBg3Tablet}
              alt="dmz background"
              className="league-background-desktop"
            />
            <img
              src={testimonialBg3Mobile}
              alt="dmz background"
              className="league-background-mobile"
            />
            <div>
              <p>
                <FormattedMessage id="new.quoteThree" defaultMessage="" />
              </p>
              <h4>- Linda</h4>
            </div>
          </div>
          <div className="testimonial-container">
            <img
              src={windowWidth > 1155 ? testimonialBg4 : testimonialBg4Tablet}
              alt="dmz background"
              className="league-background-desktop"
            />
            <img
              src={testimonialBg4Mobile}
              alt="dmz background"
              className="league-background-mobile"
            />
            <div>
              <p>
                <FormattedMessage id="new.quoteFour" defaultMessage="" />
              </p>
              <h4>- Allie</h4>
            </div>
          </div>
          <div className="testimonial-container">
            <img
              src={windowWidth > 1155 ? testimonialBg5 : testimonialBg5Tablet}
              alt="dmz background"
              className="league-background-desktop"
            />
            <img
              src={testimonialBg5Mobile}
              alt="dmz background"
              className="league-background-mobile"
            />
            <div>
              <p>
                <FormattedMessage id="new.quoteFive" defaultMessage="" />
              </p>
              <h4>- Hayley</h4>
            </div>
          </div>
          <div className="testimonial-container">
            <img
              src={windowWidth > 1155 ? testimonialBg6 : testimonialBg6Tablet}
              alt="dmz background"
              className="league-background-desktop"
            />
            <img
              src={testimonialBg6Mobile}
              alt="dmz background"
              className="league-background-mobile"
            />
            <div>
              <p>
                <FormattedMessage id="new.quoteSix" defaultMessage="" />
              </p>
              <h4>- Alex</h4>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
}

Section7.propTypes = {
  windowWidth: PropTypes.number.isRequired,
};

NextArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

NextArrow.defaultProps = {
  className: '',
  style: {},
  onClick: null,
};

PrevArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

PrevArrow.defaultProps = {
  className: '',
  style: {},
  onClick: null,
};

export default Section7;
