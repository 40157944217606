import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { COUNTRY } from '../../utils/environment';

const style = {
  h1: {
    fontWeight: '400',
  },
};

const Section6 = () => (
  <div className="section-6">
    <h1 style={style.h1}>
      <FormattedHTMLMessage
        id="new.effectiveCare"
        defaultMessage="Mental health solutions <b>can be</b> effective and accessible."
      />
    </h1>
    {COUNTRY === 'CA' &&
      <FormattedHTMLMessage
        tagName="p"
        id="new.alwaysFree"
        defaultMessage="And your first consultation is <i>always <b>free.</b></i>"
      />
    }
  </div>
);

Section6.propTypes = {};

export default Section6;
