import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import _ from 'lodash';
import { dropdownDataTwo } from './partials/dropdownData';
import DropdownComponent from './partials/dropdownComponent';
import pinkPlus from '../../images/new/animations/pinkplus.svg';
import pinkMinus from '../../images/new/animations/pinkminus.svg';

const Section10 = () => (
  <div className="section-10">
    <div className="dropdown-section">
      <FormattedHTMLMessage
        tagName="h1"
        id="new.weKnow"
        defaultMessage="We know what you’re thinking: <i>this sounds too good to be true.</i>"
      />
      {_.map(dropdownDataTwo, ({ id, question, answer }) => (
        <DropdownComponent
          key={id}
          question={question}
          answer={answer}
          plus={pinkPlus}
          minus={pinkMinus}
        />
      ))}
      {/* <div className="dark-blue-button">
        <button>
          <FormattedMessage
            id="new.chooseInbklot"
            defaultMessage="Still unsure? Why Choose Inkblot."
          />
        </button>
      </div> */}
    </div>
  </div>
);

export default Section10;
