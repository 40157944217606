/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import step3 from '../../../images/referral/step-3.png';
import french3 from '../../../images/custom/screens/group-1.png';

const featureThree = {
  stepId: 'new.stepThree',
  step: 'STEP THREE',
  id: 'eap.bookConsultation',
  title: 'Book a consultation',
  descriptionId: 'eap.afterYouMatch',
  description:
    'After you match with a provider you’re interested in, you can book a complimentary consultation before you book your first therapy session.',
  image: step3,
  frImage: french3,
};

const Section6 = props => {
  const {
    stepId,
    step,
    id,
    title,
    description,
    descriptionId,
    image,
    frImage,
  } = featureThree;
  return (
    <div className="v2-section6">
      <div className="v2-s6-text">
        <div className="text">
          <div className="step-number font-open-sans-blue-16 font-open-sans-blue-16--bold">
            {props.intl.formatMessage({ id: stepId, defaultMessage: step })}
          </div>
          <div className="step-title font-barlow-34">
            <FormattedHTMLMessage
              tagName="span"
              id={id}
              defaultMessage={title}
            />
          </div>
          <div className="step-description font-open-sans-20">
            <FormattedHTMLMessage
              tagName="span"
              id={descriptionId}
              defaultMessage={description}
            />
          </div>
        </div>
      </div>
      <div className="v2-s6-image">
        <div
          alt=""
          className="bg-url-image"
          style={{
            backgroundImage: `url(${
              localStorage.getItem('selectedLanguage') !== 'fr'
                ? image
                : frImage
            })`,
          }}
        />
        <img
          className="url-image"
          src={
            localStorage.getItem('selectedLanguage') !== 'fr' ? image : frImage
          }
          alt=""
        />
      </div>
    </div>
  );
};

Section6.propTypes = {
  intl: PropTypes.object,
};

Section6.defaultProps = {
  intl: {},
};

export default injectIntl(Section6);
