import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { press } from './partials/pressData';

const Section2 = () => (
  <div className="section-2">
    <FormattedHTMLMessage
      tagName="p"
      id="new.asSeenOn"
      defaultMessage="AS SEEN ON:"
    />
    <div className="flex-row">
      {
        press.map((publication) => (
          <img key={publication.id} src={publication.source} alt={publication.name} />
        ))
      }
    </div>
  </div>
);

Section2.propTypes = {};

export default Section2;
